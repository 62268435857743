<route>
{
  "meta": {
    "auth": "productActivity"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div slot="header" class="clearfix">
        <span>添加分类</span>
      </div>
      <el-form>
        <el-form-item label="商品一级分类" required>
          <el-select v-model="first" @change="handleClassifyChange">
            <el-option
              v-for="(i, n) in productMainCategoryList.main"
              :key="n"
              :label="i.categoryName"
              :value="i.id"
              placeholder="请选择一级分类"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品二级分类" required>
          <el-cascader
            :options="productMainCategoryList.sub"
            @change="handleChangeProductCategoryId"
            v-model="second"
            :props="{
              multiple: true,
              value: 'id',
              label: 'categoryName',
              children: 'childList'
            }"
            placeholder="请选择二级/三级分类"
            clearable
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span>
        <el-button type="primary" @click="handleConfirmClassify">
          确定
        </el-button>
      </span>
    </el-card>

    <el-card class="searchContainer">
      <div slot="header" class="clearfix">
        <span>已选分类</span>
      </div>
      <div>
        <div v-for="(item, index) in list" :key="'item' + index" class="item">
          <el-tag type="success" closable @close="handleClose(item)">{{
            `${item.categoryName} / ${item.parent.categoryName}  ${
              item.parent.parent ? ' / ' + item.parent.parent.categoryName : ''
            } `
          }}</el-tag>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productCategoryInfo: '',
      productMainCategoryList: {
        main: '',
        sub: [],
        list: []
      },
      first: '',
      second: [],
      list: []
    }
  },

  created() {
    this.getProductCategory()
    this.getAllProductCategory()
  },
  methods: {
    // 二级分类还是只能单选
    handleChangeProductCategoryId() {
      let arr = []
      this.second.forEach(item => {
        arr.push(item[0])
      })
      let uniArr = Array.from(new Set(arr))
      let arr2 = []
      if (uniArr.length === 2) {
        this.second.forEach(item => {
          if (item[0] == uniArr[0]) {
            arr2.push(item)
          }
        })
        this.$message.error('二级分类只能单选')
        this.second = arr2
      }
    },
    // 获取目前已经关联的分类
    getAllProductCategory() {
      this.$http
        .get('/boom-mix-biz-service/sysAdmin/productGroup/relationProductCategory/findList', {
          params: {
            productGroupId: this.$route.query.id
          }
        })
        .then(res => {
          this.list = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 一级分类更改
    handleClassifyChange(val) {
      this.productMainCategoryList.list.forEach(item => {
        if (item.id === val) {
          this.productMainCategoryList.sub = item.childList
        }
      })
    },

    // 获取三级全部商品分类
    getProductCategory(store) {
      function treeData(data) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].childList.length === 0) {
            data[i].childList = undefined
          } else {
            treeData(data[i].childList)
          }
        }
        return data
      }
      this.$http.get('/boom-center-product-service/sysAdmin/productCategory/all/').then(res => {
        let { childList, ...main } = res
        this.productMainCategoryList.main = main
        this.productMainCategoryList.list = treeData(res)
      })
    },
    // 确认添加分类
    handleConfirmClassify() {
      this.$http
        .post('/boom-mix-biz-service/sysAdmin/productGroup/relationProductCategory', {
          productGroupId: this.$route.query.id,
          productCategoryIdList: this.second.map(item => {
            return item[1] || item[0]
          })
        }, {
          headers: {
            ['Bm-Area-Id']: this.$route.query.areaId
          }
        })
        .then(res => {
          this.classifyDialog = false
          this.getAllProductCategory()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    // 删除关联分类
    handleClose(c) {
      const loading = this.$loading({
        lock: true,
        text: '正在删除，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http
        .post('/boom-mix-biz-service/sysAdmin/productGroup/deleteRelationProductCategory', {
          productGroupId: this.$route.query.id,
          productCategoryIdList: this.list.filter(item => {
            return +c.id === +item.id
          }).map(i => i.id)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.getAllProductCategory()
          loading.close()
        })
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  display: inline-block;
  margin: 10px;
}
</style>
